import PropTypes from 'prop-types'
import React from 'react'
import Contact from './Contact'
import pic01 from '../images/sobre.jpg'

const Main = props => {

  const close = (
    <button
      className="close"
      onClick={() => {
        props.onCloseArticle()
      }}
    >
      &nbsp;
    </button>
  )

  return (
    <div
      ref={props.setWrapperRef}
      id="main"
      style={
        props.timeout
          ? {
            display: 'flex',
          }
          : {
            display: 'none',
          }
      }
    >
      <article
        id="intro"
        className={`${props.article === 'intro' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''
          }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">Sobre</h2>

        <span className="image main">
          <img src={pic01} alt="" />
        </span>
        <p>Em 1896, nosso patriarca Sr.
          Ferdinando Cereza deixou a cidade
          de Santa Elena d’Este, no Vêneto e
          embarcou para o Brasil em busca de
          dias melhores, assim como milhões
          de outros Europeus o fizeram fugindo
          de um período difícil em que a Europa
          viveu, trazendo em sua bagagem
          tradições e grandes sonhos.</p>
        <p>
          Caneva (leia se Câneva) é uma palavra
          que se origina do dialeto Vêneto e
          quer dizer Cantina ou seja, o local
          onde se cura carnes, armazena vinhos
          e é também abastecido de grandes
          histórias e sensações. Algo muito
          comum nas casas mais antigas no
          norte Itália, onde a família armazena
          verdadeiras jóias.
        </p>
        <p>
          Depois de anos de experiência com
          Restaurantes, Deli Shops e Empórios
          Europeus, decidimos iniciar a produzir
          nossa própria “Salumeria” com carnes
          de primeira qualidade, pois em nossa
          família, esta arte sempre foi uma
          paixão unânime; contudo, no Brasil
          para degustarmos “Salumeria” de
          excelente qualidade sempre tivemos
          que recorrer a produtos importados e
          de acesso limitado. Sendo assim, o
          projeto nasce para levar ao nosso
          público, que tem o senso
          gastronômico aguçado, uma
          experiência única: a produção
          nacional atrelada à essência europeia
          com a maior parte da matéria prima
          Brasileira.
        </p>

        <p>
          Nosso trabalho prima pelo
          profissionalismo, parcerias de longo
          prazo com fornecedores qualificados,
          receitas exclusivas, carnes de primeira
          qualidade, produtos verdadeiros e
          peculiares (sem letras pequenas ou
          pegadinhas) e o mais importante
          segurança alimentar e muito carinho
          em todo o processo de fabricação
          artesanal.
        </p>
        <p>
          Os produtos artesanais tem seu
          próprio tempo, assim como os
          animais que utilizamos em nossa
          produção, que são únicos em sua
          anatomia e história e por isso há,
          como em todo ciclo da natureza, o
          tempo correto para a cura adequada ,
          atingindo o ponto perfeito, para só
          então comercializarmos.
        </p>
        <p>
          A ciência da paz e o respeito com os
          ciclos naturais contam muito para a
          nossa história e processo produtivo!"
        </p>
        {close}
      </article>

      <article
        id="contact"
        className={`${props.article === 'contact' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''
          }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">Contato</h2>
        <Contact />
        <ul className="icons">

          <li>
            <a href="https://instagram.com/In__Caneva" className="icon fa-instagram" target="_blank" rel="noreferrer">
              <span className="label">Instagram</span>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/InCaneva" className="icon fa-twitter" target="_blank" rel="noreferrer">
              <span className="label">Twitter</span>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/in-caniva-salumeria/" className="icon fa-linkedin" target="_blank" rel="noreferrer">
              <span className="label">Linkedin</span>
            </a>
          </li>

        </ul>
        {close}
      </article>




    </div>
  )
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
