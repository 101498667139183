import React from 'react'
import { useForm } from '@formspree/react';

const Contact = () => {
    const [submitState, handleSubmit] = useForm("mbjbjwlz");

    return (
        <div id="contact">
            {submitState.succeeded ?
                <h3>Obrigado por querer ser parte de nossa história! Fique tranquilo, nós protegemos seus dados de acordo com a LGPD.</h3>
                : (
                    <form method="post" onSubmit={handleSubmit}>
                        <div className="field half first" tig >
                            <label htmlFor="nome">Nome</label>
                            <input type="text" name="nome" id="nome" />
                        </div>
                        <div className="field half">
                            <label htmlFor="email">E-mail</label>
                            <input type="text" name="email" id="email" />
                        </div>
                        <div className="field half first">
                            <label htmlFor="phone">Telefone</label>
                            <input type="tel" name="phone" id="phone" />
                        </div>

                        <div className="field half">
                            <label htmlFor="where">Onde nos conheceu?</label>
                            <input type="text" name="where" id="where" />
                        </div>
                        <div className="field">
                            <label htmlFor="mensagem">Mensagem</label>
                            <textarea name="mensagem" id="mensagem" rows="4"></textarea>
                        </div>
                        <ul className="actions">
                            <li>
                                <input type="submit" value="Enviar" className="special" />
                            </li>
                            <li>
                                <input type="reset" value="Cancelar" />
                            </li>
                        </ul>
                    </form>)}
        </div>
    )
}

export default Contact




