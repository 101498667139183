import PropTypes from 'prop-types'
import React from 'react'
import logo from '../images/logo.png'
import canevaBrancoVermelho from '../images/incaneva_branco_vermelho.png'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <h1 style={{display:'none'}}>In Caneva</h1>
      {/* <span className="icon fa-diamond"></span> */}
      <span className="icon">
        <img src={logo} alt="In Caneva" />
      </span>
    </div>
    <div className="content">
      <div className="inner">
        <p>
          il vero sapore nasce
        </p>
        <div className='container'>
          <img src={canevaBrancoVermelho} alt="In Caneva" className='pic' />
        </div>

      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            sobre
          </button>
        </li>
        {/* <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Arte da Preservação
          </button>
        </li> */}
        {/* <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            Nostri Salumi
          </button>
        </li> */}
        {/* <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Onde Encontrar
          </button>
        </li> */}
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contato
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
